import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PlaceholderPageComponent from '../components/PlaceholderPageComponent';

function ListYourPropertyPage({ location }) {
  return (
    <Layout source="List Your Property Page">
      <SEO
        title="List Your Property With Us"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <PlaceholderPageComponent />
    </Layout>
  );
}

export default ListYourPropertyPage;
