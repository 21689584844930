import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDivWrapper = styled.div`
  padding-top: 89px;
  > div {
    padding: 50px 20px;
    text-align: center;
  }
  .page-placeholder {
    min-height: 250px;
    background-image: url('../../images/page-placeholder.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

function PlaceholderPageComponent() {
  return (
    <StyledDivWrapper>
      <div>
        <div className="page-placeholder" />
        <h3>This page is not yet available and is under construction - please check back soon!</h3>
        <Link to="/">
          <FontAwesomeIcon fixedWidth size="1x" icon="arrow-left" /> Go back to
          home page
        </Link>
      </div>
    </StyledDivWrapper>
  );
}

export default PlaceholderPageComponent;
